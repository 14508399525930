<template>
	<el-card v-loading="tableLoading" class="box-card">
		<div class="title">
			<!-- {{ translateTitle("账户") }} -->
			<Header />
		</div>
		<!-- <Headers /> -->
		<div class="setting-detail">
			<div class="header-title">{{ userInfo.userName || 'Hello Mark' }}
				<p v-if="iframeLink.state==0" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('未认证')}}
				</p>
				<p v-if="iframeLink.state==1" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('审核中')}}
				</p>
				<p v-if="iframeLink.state==2||iframeLink.state==3" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'"><img width="15px"
						src="../../assets/imgs/newImg/icon_gouxuan.png">{{translateTitle('已认证')}}
				</p>
			</div>
		</div>
		<div class="apply">{{translateTitle('申请MVGLOBALMASTERCARD')}}</div>
		<!-- <div class="card-detail-title">
			<div class="card-detail-title-btn" v-if="!checkApply">
				<el-button @click="add(1)" :loading="getLoading">
					{{ translateTitle("申请卡") }}</el-button>
			</div>
		</div>
		<el-table class="custom-table" v-loading="tableLoading" stripe :data="tableData" style="width: 100%">
			<el-table-column prop="email" :label="translateTitle('邮箱')" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="name" :label="translateTitle('姓名')" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="nationalId" :label="translateTitle('国家代码')" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="phoneCode" :label="translateTitle('区号')" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="link" :label="translateTitle('联系方式')" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="approvalStatus" :label="translateTitle('审核状态')" show-overflow-tooltip>
				<template v-slot="scope">
					<span v-if="scope.row.approvalStatus==-1">{{translateTitle('审核失败')}}</span>
					<span v-if="scope.row.approvalStatus==0">{{translateTitle('待审核')}}</span>
					<span v-if="scope.row.approvalStatus==1">{{translateTitle('审核成功')}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="address" :label="translateTitle('操作')">
				<template v-slot="scope">
					<el-button type="text" v-if="scope.row.approvalStatus!=1" size="mini" style="color: #786234;"
						@click="handleClickCancel(scope.row)">{{translateTitle('取消申请')}}</el-button>
					<el-button type="text" v-if="scope.row.approvalStatus!=1" size="mini" style="color: #786234;"
						@click="handleClickEdit(scope.row)">{{translateTitle('编辑')}}</el-button>
				</template>
			</el-table-column>
		</el-table> -->
		<!-- 分页 -->
		<!-- 	<Pagination :page.sync="page" :limit.sync="pageSize" :total="total" @pagination="getTableData" /> -->
		<div class="cardTitle">
			<div class="left" v-for="(item,index) in nodelist" :key="index">
				<p class="GLOBALtitle">{{item.title}}</p>
				<p style="width: 80%;margin: 0 auto;"> <img style="width: 100%;" :src="item.cardImage" alt=""></p>
				<div class="cost">
					<div style="width: 100%;">
						<p class="titlecard">{{translateTitle('限额')}}</p>
						<div class="month" style="display: flex;align-items: center;justify-content: space-between;">
							<p>{{translateTitle('每日限额')}} USD {{daylyEd(item.dailyEd)}} </p>
							<p>{{translateTitle('第一次最低充值费')}} USD {{monthMinConsume(item.monthMinConsume)}} </p>
							<!-- <p v-if="item.monthEd!=-1">{{translateTitle('每月限额')}} USD {{item.monthEd}} </p>
							<p v-else>{{translateTitle('每月限额')}} {{translateTitle('无上限')}}</p> -->
						</div>
					</div>
				<!-- 	<div style="width: 45%;">
							<div style="display: flex;align-items: center;">
							<p class="titlecard">{{translateTitle('申请条件')}}</p>
							<p style="margin-left: 10%;cursor: pointer;display: flex;">
								<img src="../../assets/imgs/icon_tishi_bai.png"
									alt="" />
							</p>
						</div>
						<div class="month">
							<p>{{translateTitle('申请费')}} USD {{item.price}} </p>
						</div>
					</div> -->
				</div>
				<div class="lock">
					<div class="lockUsdt">
						{{translateTitle('锁定USDT')}}
					</div>
					<div v-if="item.id==1">
						<el-switch
						  v-model="LockedUSDT"
						  active-color="#13ce66"
						  inactive-color="#c9cccf">
						</el-switch>
					</div>
					<div v-if="item.id==2">
						<el-switch
						  v-model="LockedUSDTTwo"
						  active-color="#13ce66"
						  inactive-color="#c9cccf">
						</el-switch>
					</div>
				</div>
				<div class="Consumer">
					<div style="border-right: 1px solid #797979;;border-bottom: 1px solid #797979;">
						<p>{{item.pointScale*100}}%</p>
						<p>{{translateTitle('品购商城消费积分回馈')}}</p>
					</div>
				<!-- 	<div style="border-bottom: 1px solid #797979;border-bottom: 1px solid #797979">
						<p>{{item.monthLeftUsd}} USD</p>
						<p>{{translateTitle('月费')}} ({{item.monthRightScale * 100}}%)</p>
					</div>
					<div style="border-right: 1px solid #797979;border-bottom: 1px solid #797979">
						<p>{{item.monthRightUsd}} USD</p>
						<p>{{translateTitle('年费')}} ({{item.monthLeftScale * 100}}%)</p>
					</div> -->
					<div style="border-bottom: 1px solid #797979">
						<p>{{item.rechargeChargeScale}}</p>
						<p>{{translateTitle('充值手续费')}}</p>
					</div>
					<div style="border-right: 1px solid #797979;padding: 30px;">
						<p v-if="language == 'EN'">USD {{item.transferChargeScale}} per transaction</p>
						<p v-else>{{translateTitle('每笔')}}USD {{item.transferChargeScale}}</p>
						<p>{{translateTitle('转账手续费')}}</p>
					</div>
					<div>
						<!-- <p>{{item.rechargeReturnScale}}</p> -->
						<p v-if="item.id==1">{{translateTitle('首次充值')}}</p>
						<p v-if="item.id==2">{{translateTitle('首次充值Sendiod')}}</p>
					</div>
				</div>
				<div class="cardCoast">
					<div class="Dedicated">
						<p>{{translateTitle('虚拟开卡费')}} USD {{cardFeelist.virtualFee}}</p>
					</div>
					<div class="Dedicated">
						<p>{{translateTitle('专属客服服务')}}</p>
						<p>{{translateTitle('免运费')}}</p>
					</div>
				</div>
				<div @click="add(item)" v-if="item.showBtn!=false" class="applyNow">
					{{translateTitle('立即申请')}}
				</div>
				<div v-if="item.showBtn==false" class="applyNowDis">
					{{translateTitle('立即申请')}}
				</div>
			</div>
		</div>
		<DialogPop width="650px" :title="translateTitle('新增卡')" :visible="visibleAgent" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('新增')" @onClose="onClose" @onSure="onSure('ruleForm')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					<!-- <el-form-item
							        :label="translateTitle('正面代理号码')"
							        prop="frontCardNo"
							      >
							        <el-input
							          v-model="ruleForm.frontCardNo"
							          clearable
							          :placeholder="translateTitle('输入12位数字')"
							        ></el-input>
							      </el-form-item> -->
					<el-form-item :label="translateTitle('名字')" prop="name">
						<el-input v-model="ruleForm.name" clearable :placeholder="translateTitle('请输入名字')"></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('邮箱')" prop="email">
						<el-input v-model="ruleForm.email" clearable :placeholder="translateTitle('请输入邮箱')"></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('国家代码')" prop="nationalId">
						<el-select class="svg-container" v-model="ruleForm.nationalId" filterable clearable
							:placeholder="translateTitle('请选择国籍')" @change="changePhoneCountry">
							<template #prefix>
								<span class="flex">
									<img v-if="phoneCountryLogo" :src="phoneCountryLogo" width="24" height="24" />
								</span>
							</template>
							<el-option v-for="item in options" :key="item.alpha2" :label="item.name"
								:value="item.alpha2">
								<div class="disal">
									<img :src="item.logo" width="24" height="24" />
									<span class="select-item">{{ item.name }}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="translateTitle('地址')" prop="address">
						<el-input v-model="ruleForm.address" clearable
							:placeholder="translateTitle('请输入地址')"></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('身份证号')" prop="idNo">
						<el-input v-model="ruleForm.idNo" clearable :placeholder="translateTitle('请输入身份证号')"></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('ID')">
						<p>{{userInfo.showId}}</p>
					</el-form-item>
					<el-form-item :label="translateTitle('联系方式')" prop="link">
						<div style="display: flex;align-items: center;">
							<el-select style="width: 30%;" class="svg-container" v-model="ruleForm.phoneCode" filterable
								clearable :placeholder="translateTitle('区号')">
								<template #prefix>
									<span class="flex">
										+
									</span>
								</template>
								<el-option v-for="item in options" :key="item.phoneCode" :label="item.phoneCode"
									:value="item.phoneCode">
									+({{item.phoneCode}}) {{item.name}}
								</el-option>
								<!-- <el-option v-for="item in options" :key="item.phoneCode" :label="item.phoneCode"
									:value="item.phoneCode">
								</el-option> -->
							</el-select>
							<el-input v-model="ruleForm.link" clearable
								:placeholder="translateTitle('请输入联系方式')"></el-input>
						</div>
					</el-form-item>
					<!-- 	<el-form-item :label="translateTitle('支付方式')" prop="payCoinId">
						<el-select class="svg-container" clearable v-model="ruleForm.payCoinId"
							:placeholder="translateTitle('请选择')" @change="changeCoinName(ruleForm.payCoinId)">
							<template #prefix>
								<span class="flex">
									<img :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_usdt@2x.png')"
										width="24" height="24" />
								</span>
							</template>
							<el-option v-for="item in option" :key="item.id" :label="item.coinName" :value="item.id">
								<div class="disal">
									<img :src="item.coinLogo" width="24" height="24" />
									<span class="select-item">{{ item.coinName }}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item> -->
				</el-form>
			</div>
		</DialogPop>
		<DialogPop width="60%" :title="translateTitle('定制你的专属卡')" :sureBtn="false" :visible="visibleCard"
			:closeBtn="false" :btnloading="btnloading" @onClose="visibleCard = false">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane :disabled='tabsDis' :label="translateTitle('定制你的专属卡')" name="first">
					<div class="change-form">
						<div style="position: relative;width: 460px;height: 291.03px;">
							<img style="width: 460px;height: 291.03px;" :src="cardImg" alt="">
							<el-input style="position: absolute;top: 54%;left: 50px;width: 79%;" v-model="cardUserName"
								:placeholder="translateTitle('请输入你卡上想显示的名字')"></el-input>
						</div>
						<div @click="nextAddcard" class="nextBtn">
							<el-button style="width: 158px;height: 48px;font-weight: 400;border: none;" class="sure-btn"
								type="primary">{{translateTitle('下一步')}}</el-button>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane :disabled='tabsDis' :label="translateTitle('新增卡')" name="second">
					<div class="change-form">
						<el-form v-if="activeName=='second'" label-position="top" :model="ruleForm" status-icon
							:rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
							hide-required-asterisk>
							<!-- <el-form-item
				  				        :label="translateTitle('正面代理号码')"
				  				        prop="frontCardNo"
				  				      >
				  				        <el-input
				  				          v-model="ruleForm.frontCardNo"
				  				          clearable
				  				          :placeholder="translateTitle('输入12位数字')"
				  				        ></el-input>
				  				      </el-form-item> -->
							<el-form-item :label="translateTitle('名字')" prop="name">
								<el-input v-model="ruleForm.name" clearable
									:placeholder="translateTitle('请输入名字')"></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('邮箱')" prop="email">
								<el-input v-model="ruleForm.email" clearable
									:placeholder="translateTitle('请输入邮箱')"></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('国家代码')" prop="nationalId">
								<el-select class="svg-container" v-model="ruleForm.nationalId" filterable clearable
									:placeholder="translateTitle('请选择国籍')" @change="changePhoneCountry">
									<template #prefix>
										<span class="flex">
											<img v-if="phoneCountryLogo" :src="phoneCountryLogo" width="24"
												height="24" />
										</span>
									</template>
									<el-option v-for="item in options" :key="item.alpha2" :label="item.name"
										:value="item.alpha2">
										<div class="disal">
											<img :src="item.logo" width="24" height="24" />
											<span class="select-item">{{ item.name }}</span>
										</div>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="translateTitle('邮编')" prop="zipCode">
								<el-input v-model="ruleForm.zipCode" clearable
									:placeholder="translateTitle('请输入邮编')"></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('地址')" prop="address">
								<el-input v-model="ruleForm.address" clearable
									:placeholder="translateTitle('请输入地址')"></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('身份证号')" prop="idNo">
								<el-input v-model="ruleForm.idNo" clearable
									:placeholder="translateTitle('请输入身份证号')"></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('ID')">
								<p>{{userInfo.showId}}</p>
							</el-form-item>
							<el-form-item :label="translateTitle('联系方式')" prop="link">
								<div style="display: flex;align-items: center;">
									<el-select style="width: 30%;" class="svg-container" v-model="ruleForm.phoneCode"
										filterable clearable :placeholder="translateTitle('区号')">
										<template #prefix>
											<span class="flex">
												+
											</span>
										</template>
										<el-option v-for="item in options" :key="item.phoneCode" :label="item.phoneCode"
											:value="item.phoneCode">
											+({{item.phoneCode}}) {{item.name}}
										</el-option>
										<!-- <el-option v-for="item in options" :key="item.phoneCode" :label="item.phoneCode"
				  						:value="item.phoneCode">
				  					</el-option> -->
									</el-select>
									<el-input v-model="ruleForm.link" clearable
										:placeholder="translateTitle('请输入联系方式')"></el-input>
								</div>
							</el-form-item>
							<!-- <el-form-item :label="translateTitle('支付方式')" prop="payCoinId">
								<el-select class="svg-container" clearable v-model="ruleForm.payCoinId"
									:placeholder="translateTitle('请选择')" @change="changeCoinName(ruleForm.payCoinId)">
									<template #prefix>
										<span class="flex">
											<img :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_usdt@2x.png')"
												width="24" height="24" />
										</span>
									</template>
									<el-option v-for="item in option" :key="item.id" :label="item.coinName"
										:value="item.id">
										<div class="disal">
											<img :src="item.coinLogo" width="24" height="24" />
											<span class="select-item">{{ item.coinName }}</span>
										</div>
									</el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item class="nextBtn">
								<el-button @click="nextOrder('ruleForm')"
									style="width: 158px;height: 48px;font-weight: 400;border: none;" class="sure-btn"
									type="primary">{{translateTitle('下一步')}}</el-button>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane :disabled='tabsDis' :label="translateTitle('确认订单')" name="third">
					<div class="change-form">
						<div style="margin: 20px 0;font-weight: bold;">
							{{translateTitle('你的实体卡将邮寄到下列地址')}}
						</div>
						<el-form label-position="top" :model="ruleForm" status-icon ref="ruleForm" label-width="100px"
							class="demo-ruleForm" hide-required-asterisk>
							<el-form-item :label="translateTitle('地址')" prop="googleValidateCode">
								<el-input disabled v-model="ruleForm.address" clearable></el-input>
							</el-form-item>
							<p style="margin: 20px 0;font-weight: bold;">{{translateTitle('持卡人信息')}}</p>
							<el-form-item :label="translateTitle('持卡人名字')" prop="googleValidateCode">
								<el-input disabled v-model="ruleForm.name" clearable></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('身份证号')" prop="googleValidateCode">
								<el-input disabled v-model="ruleForm.idNo" clearable></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('电邮地址')" prop="googleValidateCode">
								<el-input v-model="ruleForm.email" clearable disabled></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('电话号码')" prop="googleValidateCode">
								<el-input v-model="ruleForm.link" clearable disabled></el-input>
							</el-form-item>
							<el-form-item :label="translateTitle('价格')" prop="googleValidateCode">
								<p>{{price}} USD</p>
							</el-form-item>
							<el-form-item>
								<div class="nextBtnEdit">
									<el-button @click="nextOrderEdit('ruleForm')"
										style="width: 158px;height: 48px;font-weight: 400;border: none;"
										class="sure-btn">{{translateTitle('上一步')}}</el-button>
									<el-button @click="nextOrderNext('ruleForm')"
										style="width: 158px;height: 48px;font-weight: 400;border: none;"
										class="sure-btn" type="primary">{{translateTitle('下一步')}}</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane :disabled='tabsDis' :label="translateTitle('支付')" name="fourth">
					<div class="change-form">
						<el-form label-position="top" :model="ruleForm" status-icon ref="ruleForm" label-width="100px"
							class="demo-ruleForm" hide-required-asterisk>
							<el-form-item :label="translateTitle('支付')">
								<!-- <el-checkbox-group v-model="ruleForm.type" style="display: grid;">
									<el-checkbox label="区块链" name="type"></el-checkbox>
								</el-checkbox-group> -->
								<div>{{translateTitle('区块链')}}</div>
								<el-radio-group v-model="payCoinId" style="display: grid;">
									<div style="display: grid;">
										<el-radio style="display: flex;align-items: center;padding: 10px 0;" v-for="(item,index) in option" :key="item.id" @change="handleChecked(item)" :label="item.id">
											<div style="display: flex;align-items: center;">
												<img style="margin-right: 10px;" width="24" height="24"
													:src="item.coinLogo" alt="">
												<p> {{item.coinName}} {{item.amount}}</p>
											</div>
										</el-radio>
									</div>
								</el-radio-group>
								<!-- <div>{{translateTitle('网上银行')}}</div>
								<el-radio-group v-model="netCard" style="display: grid;">
									<el-radio style="display: flex;align-items: center;padding: 10px 0;"  name="type">
                                        <div style="display: flex;align-items: center;">
                                        	<img style="margin-right: 10px;" width="24" height="24"
                                        		src="../../assets/imgs/newImg/netCard.png" alt="">
                                        	<p>{{translateTitle('网上银行')}}</p>
                                        </div>
									</el-radio>
								</el-radio-group>
								<div>{{translateTitle('信用卡银行卡')}}</div>
								<el-radio-group v-model="bankCrad" style="display: grid;">
									<el-radio style="display: flex;align-items: center;padding: 10px 0;" name="type">
										<div style="display: flex;align-items: center;">
											<img style="margin-right: 10px;" width="24" height="24"
												src="../../assets/imgs/newImg/bankCrad.png" alt="">
											<p>{{translateTitle('信用卡银行卡')}}</p>
										</div>
									</el-radio>
								</el-radio-group> -->
							</el-form-item>
							<el-form-item>
								<div class="nextBtn">
									<el-button @click="nextOrderGoogel('ruleForm')"
										style="width: 158px;height: 48px;font-weight: 400;border: none;"
										class="sure-btn" type="primary">{{translateTitle('立即支付')}}</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
			</el-tabs>
		</DialogPop>
		<DialogPop width="500px" :title="translateTitle('谷歌验证码')" :visible="visibleGoogel" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('确定')" @onClose="onCloseGoogel"
			@onSure="onSureGoogel('loginForm1')">
			<div class="change-form">
				<el-form label-position="top" :model="loginForm1" status-icon :rules="loginRules" ref="loginForm1"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('谷歌验证码')" prop="googleValidateCode">
						<el-input v-model="loginForm1.googleValidateCode" clearable
							:placeholder="translateTitle('请输入谷歌验证码')"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>
		<DialogPop width="600px" :show-close=false :title="translateTitle('确认订单')" :visible="visibleOrder"
			:btnloading="btnloading" :closeText="translateTitle('编辑')" :sureText="translateTitle('确定')"
			@onClose="editCard" @onSure="onSureOrder('ruleForm')">
			<div class="change-form">
				<div style="margin: 20px 0;font-weight: bold;">
					{{translateTitle('你的实体卡将邮寄到下列地址')}}
				</div>
				<el-form label-position="top" :model="ruleForm" status-icon ref="ruleForm" label-width="100px"
					class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('地址')" prop="googleValidateCode">
						<el-input disabled v-model="ruleForm.address" clearable></el-input>
					</el-form-item>
					<p style="margin: 20px 0;font-weight: bold;">{{translateTitle('持卡人信息')}}</p>
					<el-form-item :label="translateTitle('持卡人名字')" prop="googleValidateCode">
						<el-input disabled v-model="ruleForm.name" clearable></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('身份证号码')" prop="googleValidateCode">
						<el-input disabled v-model="ruleForm.idNo" clearable></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('电邮地址')" prop="googleValidateCode">
						<el-input v-model="ruleForm.email" clearable disabled></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('电话号码')" prop="googleValidateCode">
						<el-input v-model="ruleForm.link" clearable disabled></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('价格')" prop="googleValidateCode">
						<p>{{price}} USD</p>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>

	</el-card>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		getToken
	} from "@/utils/token";
	import Headers from "../../components/accountHeader/index.vue";
	import topHeader from "../../components/topHeader/index"
	import {
		proxyList,
		addProxy,
		applyPage,
		applyNode,
		checkOnceApply,
		cancelApply,
		nodelist,
		kycStatus,
		userKycDetails
	} from "@/api/kyc";
	import {
		getNational
	} from "@/api/accountManage";
	import {
		cardFeeCoins,
		cardFee
	} from "@/api/card.js";
	import axios from "axios";
	import {
		translateTitle
	} from "@/utils/i18n";
	export default {
		components: {
			Headers,
			topHeader
		},
		filters: {
			statusFilter(status) {
				const statusMap = {
					0: '未上传',
					1: '审核中',
					2: '银行已拒绝',
					3: '银行审核通过',
				}
				return statusMap[status]
			}
		},
		data() {
			return {
				value1: false,
				visibleCard: false,
				visibleGoogel: false,
				visibleOrder: false,
				tabsDis: true,
				total: 20,
				size: 10,
				tableData: [],
				tableLoading: false,
				LockedUSDT:'false',
				LockedUSDTTwo:'fasle',
				checkApply: '',
				options1: ["passport", "IdentityCard"],
				uploadHeader: {
					token: getToken(),
				},
				loginForm1: {
					googleValidateCode: '',
				},
				loginRules: {
					googleValidateCode: [{
						required: true,
						message: this.translateTitle("请输入谷歌验证码"),
						trigger: "change",
					}, ],
				},
				phoneCountryLogo: '',
				rules: {
					email: [{
						required: true,
						message: this.translateTitle("请输入邮箱"),
						trigger: 'blur'
					}, ],
					name: [{
						required: true,
						message: this.translateTitle("请输入昵称"),
						trigger: 'blur'
					}, ],
					address: [{
						required: true,
						message: this.translateTitle("请输入地址"),
						trigger: 'blur'
					}, ],
					idNo: [{
						required: true,
						message: this.translateTitle("请输入身份证号"),
						trigger: 'blur'
					}, ],
					link: [{
						required: true,
						message: this.translateTitle("请输入联系方式"),
						trigger: 'blur'
					}],
					nationalId: [{
						required: true,
						message: this.translateTitle("请选择国籍"),
						trigger: "change"
					}, ],
					zipCode: [{
						required: true,
						message: this.translateTitle("请输入邮编"),
						trigger: "change"
					}, ],
					payCoinId: [{
						required: true,
						message: this.translateTitle("请选择支付方式"),
						trigger: "change"
					}, ],
				},
				bindingTitle: this.translateTitle("新增KYC"),
				ruleForm: {
					link: '',
				},
				activeName: "first",
				pageSize: 10,
				page: 1,
				Visible: false,
				options: [],
				dialogVisible: false,
				btnloading: false,
				checkRow: {},
				kycType: "",
				fileUrl: process.env.NODE_ENV == 'production' ? 'https://api.bankeracard.com/client_side' :
					'http://13.214.25.79:9001/client_side',
				kycStatusInfo: {},
				iframeLink: {},
				visibleAgent: false,
				getLoading: false,
				nodelist: [],
				cardUserName: '',
				nodeId: '',
				cardImg: '',
				choiceCoin: {},
				option: [],
				price: '',
				cardFeelist: {},
				activeName: 'first',
				netCard:'',
				bankCrad:'',
				type:[],
				payCoinId:'',
				language:''
				
			};
		},
		computed: {
			...mapState({
				userInfo: (state) => state.user.user,
			}),
		},
		created() {
			this.language = JSON.parse(localStorage.getItem('language'))&& JSON.parse(localStorage.getItem('language')).language?JSON.parse(localStorage.getItem('language')).language:'EN'
			this.getTableData()
			this.getNationalData()
			this.getCheckOnceApply()
			this.getNodelist()
			this.getcardFeeCoins()
			this.getcardFee()
			this.userKycDetails()
		},
		methods: {
			translateTitle,
			//获取分页数据
			async getTableData() {
				const data = {
					pageSize: this.pageSize,
					page: this.page,
				};
				const loading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				// this.tableLoading= true
				let res = await applyPage(data);
				if (res.code == 200) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					// this.tableLoading= false
					loading.close()
				} else {
					this.$message.error(this.translateTitle(res.msg));
				}
			},
			async getNodelist() {
				let res = await nodelist()
				this.nodelist = res.data
			},
			daylyEd(item){
				return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			monthMinConsume(item){
				return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			editCard() {
				this.visibleAgent = true
			},
			onCloseGoogel() {
				//   this.$refs[formName].resetFields();
				this.visibleGoogel = false;
				this.loading = false;
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			nextAddcard() {
				if (this.cardUserName == '') {
					this.$message.error(this.translateTitle("请输入卡片名字"));
					return
				} else {
					this.activeName = 'second'
				}
			},
			nextOrder(ruleForm) {
				this.$refs[ruleForm].validate((valid) => {
					console.log(valid, 'valid', ruleForm)
					if (valid) {
						this.activeName = 'third'
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			nextOrderEdit() {
				this.activeName = 'first'
			},
			nextOrderNext() {
				this.activeName = 'fourth'
				this.tabsDis = false
			},
			nextOrderGoogel() {
				console.log(this.ruleForm,'this.ruleForm',this.payCoinId)
				if (this.choiceCoin.amount < this.cardFeelist.virtualFee) {
					this.$message.error(this.translateTitle("余额不足"));
					return
				} else if (this.payCoinId == '') {
					this.$message.error(this.translateTitle("请选择支付方式"));
					return
				}
				else if (this.payCoinId > 5) {
					return
				}
				else {
					this.visibleGoogel = true
				}
			},
			handleChecked(item) {
				this.ruleForm.payCoinId = item.id
				this.choiceCoin.amount = item.amount;
			},
			async getCheckOnceApply() {
				let res = await checkOnceApply()
				this.checkApply = res.data
			},
			async getcardFee() {
				let res = await cardFee()
				this.cardFeelist = res.data
			},
			handleBeforeUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isJPG =
					file.type === 'image/jpeg' ||
					file.type === 'image/jpg' ||
					file.type === 'image/png'
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error(this.translateTitle('格式错误'));
				}
				if (!isLt2M) {
					this.$message.error(this.translateTitle("请选择小于2M的文件"));
				}
				this.loading = true;
				return isJPG && isLt2M;
			},
			handleError() {
				this.loading = false;
				this.$message.error(this.translateTitle("上传失败"));
			},
			async userKycDetails() {
				let res = await userKycDetails();
				if (res.code == 200) {
					this.iframeLink = res.data
				} else {
					this.$message.error(res.msg);
				}
			},
			//获取kyc状态
			async getKycStatus() {
				let res = await kycStatus();
				if (res.code == 200) {
					this.kycStatusInfo = res.data
				} else {
					this.$message.error(res.msg);
				}
			},
			handleUploadSuccess(res) {
				this.loading = false;
				if (res.code !== 200) {
					this.$message.warning(res.msg);
					return;
				}
				this.$message.success(this.translateTitle("上传成功"));
				this.ruleForm.filePath = res.data.filePath;
				this.ruleForm.fileName = res.data.fileName;
				// Object.assign(this.uploadRow, res.data)
			},
			handlePictureCardPreview(file) {
				this.dialogVisible = true;
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			showdialog(row) {
				this.dialogVisible = true;
				this.checkRow = row;
			},
			next() {
				if (this.cardUserName == '') {
					this.$message.error(this.translateTitle("请输入卡片名字"));
					return
				}
				this.visibleCard = false
				this.visibleAgent = true
			},
			onClose() {
				// this.$refs["ruleForm"].resetFields();
				this.visibleAgent = false;
				this.ruleForm = {}
				this.phoneCountryLogo = ''
			},
			onSure(ruleForm) {
				if (this.choiceCoin.amount < this.cardFeelist.virtualFee) {
					this.$message.error(this.translateTitle("余额不足"));
					return
				} else {
					this.visibleOrder = true
					this.visibleAgent = false
				}
			},
			onSureGoogel(ruleForm) {
				let params;
				let _request;
				this.ruleForm.cardUserName = this.cardUserName
				this.ruleForm.googleCode = this.loginForm1.googleValidateCode
				this.ruleForm.nodeId = this.nodeId
				this.$refs[ruleForm].validate((valid) => {
					if (valid) {
						this.btnloading = true
						applyNode(this.ruleForm).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getNodelist();
								this.visibleGoogel = false;
								this.ruleForm = {}
								this.cardUserName = ''
								this.phoneCountryLogo = ''
								// this.$refs["ruleForm"].resetFields();
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							this.btnloading = false
						});
						// alert("submit!");
					} else {
						return false;
					}
				});
			},
			onSureOrder(ruleForm) {
				this.visibleGoogel = true
				this.visibleOrder = false

			},
			handleClickBtn(type, type1, val) {
				this.Visible = true;

				this.getNationalData();
				if (type == "edit") {
					this.kycType = val.fileType;
					this.ruleForm.fileType = val.fileType;
					this.ruleForm = val;

					this.bindingTitle = val.fileType == "2" ? this.translateTitle("编辑KYC地址") : this.translateTitle(
						"编辑KYC身份");
				} else if (type == "add") {
					this.kycType = type1;
					this.ruleForm.fileType = type1;
					type1 == "2" ? this.$router.push('/kycAddress') : this.$router.push('/kycIdentity');
				} else {
					this.$router.push('/kycDetail')
				}
			},
			async getNationalData() {
				let res = await getNational();
				this.options = res.data;
				// this.changePhoneCountry(this.ruleForm.nationalId)
				// this.getphoneCountryLogo(val)
			},
			async getcardFeeCoins() {
				let res = await cardFeeCoins()
				this.option = res.data;
				this.option.push({
						coinName: this.translateTitle('网上银行'),
						index: '6',
						coinLogo:require('../../assets/imgs/newImg/netCard.png'),
						id: 6,
					},
					{
						coinName: this.translateTitle('信用卡银行卡'),
						index: '7',
						coinLogo:require('../../assets/imgs/newImg/bankCrad.png'),
						id: 7,
					},)
			},
			changeCoinName(option) {
				let obj = this.option.find(item => item.id === option)
				// this.choiceCoin.coinName = obj.coinName;
				this.choiceCoin.coinLogo = obj.coinLogo;
				this.choiceCoin.amount = obj.amount;
				// this.choiceCoin.toUsdtRate = obj.toUsdtRate;
				// this.ruleForm.coinLink = obj.coinLink;
				// this.ruleForm.coinName = obj.coinName
				// this.ruleForm.coinId = obj.id
			},
			changePhoneCountry(data) {
				let obj = this.options.find(item => item.alpha2 === data)
				this.phoneCountryLogo = obj.logo
				this.ruleForm.phoneCode = obj.phoneCode
			},
			handleClickEdit(row) {
				this.ruleForm = JSON.parse(JSON.stringify(row))
				this.visibleAgent = true
				this.changePhoneCountry(this.ruleForm.nationalId)
			},
			add(e) {
				this.ruleForm = {}
				this.activeName = 'first'
				this.cardImg = e.cardImage
				this.price = e.price
				this.nodeId = e.id
				this.visibleCard = true
				this.cardUserName = ''
				this.phoneCountryLogo = ''
			},
			handleClickCancel(row) {
				this.$confirm(
						this.translateTitle("是否取消申请"),
						this.translateTitle("提示"), {
							confirmButtonText: this.translateTitle("确定"),
							cancelButtonText: this.translateTitle("取消"),
							type: "",
							customClass: 'custom-message',
						}
					)
					.then(async () => {
						let data = {
							id: row.id
						}
						const res = await cancelApply(data);
						if (res.code == 200) {
							this.$message.success(this.translateTitle("操作成功"));
							this.ruleForm = {}
							this.getTableData()
							this.getCheckOnceApply()
							this.phoneCountryLogo = ''
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}
					})
					.catch(() => {});
			}
		},
	};
</script>

<style lang="less" scoped>
	.nextBtn {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
	}
    /deep/ .el-radio__input{
		display: flex;
		    align-items: center;
	}
	.nextBtnEdit {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
	}

	.title {
		font-size: 26px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-bottom: 20px;
		text-align: right;
	}

	.setting-detail {
		font-size: 22px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-top: 30px;
		margin-bottom: 30px;
		display: flex;

		.header-title {
			font-size: 36px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			font-weight: bold;
			color: #31363E;
			line-height: 26px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			.tip {
				margin-left: 24px;
				background: rgba(55, 34, 0, 0.1);
				border-radius: 4px;
				color: rgba(144, 117, 54, 1);
				padding: 4px 10px;
				border: 1px solid rgba(55, 34, 0, 0.8);
				font-size: 15px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				line-height: 26px;

				img {
					margin-right: 5px;
					vertical-align: middle;
				}
			}

			.gray {
				background: rgba(55, 34, 0, 0.1);
				border: 1px solid rgba(55, 34, 0, 0.8);
			}
		}
	}

	.succ {
		color: #3ad3b9;
	}

	.err {
		color: #d33a3a;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
		margin-bottom: 20px;
	}

	.bg-purple {
		background: #F7F6F5;
	}

	.common-color {
		color: #786234 !important;
	}

	.grid-content {
		border-radius: 10px;
		padding: 30px;
		min-height: 36px;

		.top {
			img {
				vertical-align: middle;
				margin-right: 10px;
				margin-top: -3px;
			}

			font-size: 18px;
			font-family: Microsoft YaHei-Bold,
			Microsoft YaHei;
			font-weight: 700;
		}

		.bottom {
			margin-top: 50px;
			text-align: right;
		}
	}

	.iframe {
		iframe {
			width: 100%;
			height: 500px;
		}

		width: 80%;
		margin: 0 auto;
		height: 500px;
	}

	.card-detail-title {
		font-size: 22px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-top: 30px;
		margin-bottom: 30px;
		display: flex;
		justify-content: flex-end;
		align-items: end;

		.card-detail-title-btn .el-button {
			width: 262px;
			height: 66px;
			background: #F7F6F5;
			border: 1px solid #F7F6F5;
			border-radius: 17px;
			color: #31363E;
			font-size: 16px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
		}
	}

	.apply {
		font-size: 30px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #31363E;
	}

	.cardTitle {
		display: flex;
		align-items: center;
		margin-top: 40px;

		.left {
			background: #F8FAFB;
			border-radius: 12px;
			padding: 30px;
			margin-right: 20px;

			max-width: 35%;

			.GLOBALtitle {
				margin-bottom: 10px;
				font-family: Microsoft YaHei;
				font-size: 14px;
				font-weight: bold;
			}

			.cost {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 20px;
				font-size: 14px;
				margin-bottom: 20px;

				.titlecard {
					font-family: Microsoft YaHei;
					font-size: 14px;
					font-weight: normal;
					color: #797979;
				}

				.month {
					margin-top: 20px;

					p {
						padding: 5px 0;
						font-family: Microsoft YaHei;
						color: #797979;
					}
				}
			}

			.Consumer {
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				font-size: 14px;

				div {
					width: 50%;
					text-align: center;
					padding: 20px;
                    min-height: 109px;
					p {
						padding: 5px 0;
						font-family: Microsoft YaHei;
						color: #797979;
					}
				}
			}

			.cardCoast {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-family: Microsoft YaHei;
				color: #797979;
				font-size: 14px;

				.Dedicated {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 43%;
				}
			}

			.applyNow {
				margin-top: 20px;
				text-align: center;
				background: #E7EEF7;
				border-radius: 12px;
				font-family: Microsoft YaHei;
				font-size: 15px;
				font-weight: normal;
				color: #2B6AAC;
				padding: 14px 64px;
				cursor: pointer;
			}

			.applyNowDis {
				margin-top: 20px;
				text-align: center;
				background: #E7EEF7;
				border-radius: 12px;
				font-family: Microsoft YaHei;
				font-size: 15px;
				font-weight: normal;
				color: #797979;
				padding: 14px 64px;
				cursor: pointer;
			}
		}
	}
	.lock{
		display: flex;
		align-items: center;
		background: #FFFFFF;
		justify-content: space-between;
		padding: 15px;
		margin: 0 auto;
		width: 100%;
		border-radius: 10px;
		.lockUsdt{
			font-weight: bold;
		}
	}
</style>